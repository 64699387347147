import $ from 'jquery';

function SearchCtrl() {
	this.$win = $(window);
	this.$body = $('body');
	this.$btnSearch = $('.header-right-toggle');
	this.$headerSearch = $('#headerSearch');

	this.click = this.click.bind(this);
	this.close = this.close.bind(this);

	this.init();
}

SearchCtrl.prototype.init = function() {
	this.$btnSearch.on('click', this.click);
	this.$headerSearch.find('.header-search-list a').on('click', this.close);
}

SearchCtrl.prototype.click = function(e) {
	e.preventDefault();
	let toggleClassName = 'header-search-active';
	if (this.$btnSearch.hasClass(toggleClassName)) {
		this.$body.removeClass(toggleClassName);
		this.$headerSearch.removeClass(toggleClassName);
		this.$btnSearch.removeClass(toggleClassName);
		this.$btnSearch.find('.ja').text('どんな記事をお探しですか？');
	} else {
		this.$body.addClass(toggleClassName);
		this.$headerSearch.addClass(toggleClassName);
		this.$btnSearch.addClass(toggleClassName);
		this.$btnSearch.find('.ja').text('閉じる');
	}
}

SearchCtrl.prototype.close = function(e) {
	let toggleClassName = 'header-search-active';
	if (this.$btnSearch.hasClass(toggleClassName)) {
		this.$body.removeClass(toggleClassName);
		this.$headerSearch.removeClass(toggleClassName);
		this.$btnSearch.removeClass(toggleClassName);
		this.$btnSearch.find('.ja').text('どんな記事をお探しですか？');
	}
}

export default SearchCtrl;